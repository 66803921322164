export const TranslatableText = {
  getNewTranslatableTextItem(isNewId, stringValue = '', langCode = '') {
    //A TranslatableTextItem is a sub-component of a TranslatableText object.
    return {
      existsInDb: false,
      stringValue,
      languageCode: langCode ? langCode : 'en'
    }
  },
  
  getNew(isNewId, stringValue = '') {
    //Get a new TranslatableText object with optional new ID and stringValue.
    return {
      enText: this.parentObject.getNewTranslatableTextItem(true, stringValue, 'en'),
      translations: [],
      machineTranslations: []
    }
  },
  
  getStringValue(translatableText, langCode = 'en', isMachineTranslationsEnabled = true) {
    //Return the string value for the specified language code.
    if (langCode === 'en' && translatableText?.enText) {
      return translatableText?.enText.stringValue
    } else if (langCode !== 'en') {
      //The Wetu logic is: Show manual translation if available else display machine translation.
      const manualTranslation = translatableText?.translations?.find(x => x.languageCode === langCode)
      const machineTranslation = translatableText?.machineTranslations?.find(x => x.languageCode === langCode)
      //An empty manualTranslation.stringValue is allowed because if they delete the machine translation an empty manual translation should be sent to the TranslatableTextEditor.
      return manualTranslation ? manualTranslation.stringValue : isMachineTranslationsEnabled && machineTranslation?.stringValue ? machineTranslation.stringValue : ''
    }
    return '' 
  },

  isShowMachineTranslation(translatableText, langCode = 'en') {
    //The Wetu logic is: Show manual translation if available else display machine translation.
    const manual = translatableText?.translations?.find(x => x?.languageCode === langCode)
    if (langCode === 'en' || typeof manual?.stringValue === 'string') { //The TranslatableTextEditor uses this object differently and stores the DraftJs object in the stringValue when it initializes. So we must check if this is a valid string value.
      return false
    } else if (!!translatableText?.machineTranslations.find(x => x.languageCode === langCode)) {
      return true
    }
  },
  
  hasTranslation(translatableText, langCode = 'en') {
    //Returns a boolean to indicate if TranslatableText has a translation for the specified language.
    if (langCode === 'en' && translatableText?.enText?.stringValue?.trim()) {
      return true
    } else if (langCode !== 'en' && translatableText?.translations) {
      return !!translatableText.translations.find(x => x.languageCode === langCode && (x.stringValue || x.stringValue?.trim()))
    }
    return false
  },

  hasAnyTranslation(translatableText) {
    //Returns a boolean to indicate if translatableText has any translations.
    if (translatableText.translations?.length) {
      translatableText.translations.forEach(item => {
        if (item.stringValue) {
          return true
        }
      })
    }
    return false
  },

  get(translatableText, langCode, isMachineTranslationsEnabled = true) {
    //Returns the TranslatableTextItem for specified langCode (A TranslatableTextItem is a sub-component of a TranslatableText object).
    if (langCode === 'en') {
      return translatableText.enText
    } else {
      const manual = translatableText?.translations?.find(x => x.languageCode === langCode)
      const machine = translatableText?.machineTranslations?.find(x => x.languageCode === langCode)
      return manual ? manual : isMachineTranslationsEnabled ? machine : null
    } 
  },

  getDefaultStringValue(translatableText) {
    //Return the default string for a translatableText object. Assume enText first, then if no enText pick the first translation available.
    if (translatableText?.enText) {
      return translatableText.enText.stringValue
    }
    if (translatableText?.translations) {
      //Return the first translation
      translatableText.translations.forEach(item => {
        if (item.stringValue) {
          return item.stringValue
        }
      })
    }
    if (translatableText?.machineTranslations) {
      translatableText.machineTranslations.forEach(item => {
        if (item.stringValue) {
          return item.stringValue
        }
      })
    }
    return ''
  },
  
  update(translatableText, langCode, stringValue, setIsChanged = false) {
    //Updates a translatableText object with inputString, for the specified language code.
    if (langCode === 'en') {
      if (!translatableText.enText.id) {
        translatableText.enText.existsInDb = false
        translatableText.enText.id = 0
      }
      translatableText.enText.stringValue = stringValue
      if (setIsChanged) {
        translatableText.enText.isChanged = true
      }
    } else {
      let translation = translatableText.translations.find(x => x.languageCode === langCode)
      if (translation) {
        translation.stringValue = stringValue
      } else {
        translation = this.parentObject.getNewTranslatableTextItem(true, stringValue, langCode)
        translatableText.translations.push(translation)
      }
      if (setIsChanged) {
        translation.isChanged = true
      }
    }
  },

  updateItem(translatableText, langCode, translatableTextItem) {
    //Updates a translatableText object with inputString, for the specified language code.
    //Called on load (initialisation) of TranslatableTextEditor, hence writing to the machineTranslations array.
    if (langCode === 'en') {
        translatableText.enText = translatableTextItem
    } else {
      let manual = translatableText.translations.find(x => x.languageCode === langCode)
      let machine = translatableText.machineTranslations.find(x => x.languageCode === langCode)
      if (manual) { 
        manual = translatableTextItem   //By-ref object update.
      } else if (machine) {
        machine = translatableTextItem
      }
    }
  },

  setEnIsChanged(translatableText, isChanged) {
    //If en text changes we need to create new machine translations, so mark isChanged=true.
    if (translatableText?.enText) {
      translatableText.enText.isChanged = isChanged
    }
  },
 
  updateExistsInDb(translatableText, langCode, existsInDb) {
    //Updates a translatableText object with inputString, for the specified language code.
    if (langCode === 'en') {
        translatableText.enText.existsInDb = existsInDb
    } else {
      let translation = translatableText.translations.find(x => x.languageCode === langCode)
      if (translation) {
        translation.existsInDb = existsInDb
      } else {
        translation = this.parentObject.getNewTranslatableTextItem(true, '', langCode)
        translation.existsInDb = existsInDb
        translatableText.translations.push(translation)
      }
    }
  },
  
  getIds(translatableText) {
    //Returns a list of all IDs in translatableText.
    const ids = []
    if (translatableText) {
      if (translatableText.enText?.id) {
        ids.push(translatableText.enText.id)
      }
      if (translatableText?.translations) {
        translatableText.translations.forEach(item => {
          ids.push(item.id)
        })
      }
      if (translatableText?.machineTranslations) {
        translatableText.machineTranslations.forEach(item => {
          ids.push(item.id)
        })
      }
    }
    return ids
  },
  
  setAllExistsInDb(translatableText, isExists) {
    //Sets existsInDb flags for all items to isExists.
    if (translatableText?.enText) {
      translatableText.enText.existsInDb = isExists
    }
    if (translatableText?.translations) {
      translatableText.translations.forEach(item => {
        item.existsInDb = isExists
      })
    }
    if (translatableText?.machineTranslations) {
      translatableText.machineTranslations.forEach(item => {
        item.existsInDb = isExists
      })
    }
  },

  deleteTranslatableTextItem(translatableText, langCode) {
    //Deletes a translatableTextItem from a translations[] array (or resets enText) based on langCode.
    if (langCode === 'en') {
      translatableText.enText = this.parentObject.getNewTranslatableTextItem(true, '')
    } else if (translatableText?.translations) {
      const findIndex = translatableText?.translations.findIndex(x => x.languageCode === langCode)
      if (findIndex >= 0) {
        translatableText.translations.splice(findIndex, 1)
      }
    }
  },

  isEmpty(translatableText) {
    // Check if the TranslatableText has a value
    return translatableText?.enText?.stringValue || translatableText?.translations?.length
  },

  clearStringValues(translatableText) {
    //Sets the stringValue to '' for all items.
    if (translatableText) {
      if (translatableText.enText) {
        translatableText.enText.stringValue = ''
      }
      translatableText?.translations?.forEach(item => { item.stringValue = '' })
      translatableText?.machineTranslations?.forEach(item => { item.stringValue = '' })
    }
  },

  repair(translatableText) {
    //API removes object properties (leading to undefined errors) if they have a null or empty value.
    if (!translatableText) {
      translatableText = this.getNew()
    }
    if (!translatableText.enText) {
      translatableText.enText = this.parentObject.getNewTranslatableTextItem(true, stringValue, 'en')
    }
    if (!translatableText.enText.stringValue) {
      translatableText.enText.stringValue = ''
    }
    if (!translatableText.translations || (translatableText.translations?.length === 1 && !translatableText.translations[0])) {
      translatableText.translations = []
    }
    if (!translatableText.machineTranslations) {
      translatableText.machineTranslations = []
    }
  },

  removeBlankItems(translatableText) {
    //The TranslatableTextEditor creates empty translation items that do not exist in DB (if you delete a MT and hit Save it creates one).
    if (translatableText?.translations) {
      translatableText.translations = translatableText.translations.filter(x => !(!x.stringValue && !x.existsInDb))
    }
  },

  translatableTextHaveChanges(initialText, newText) {
    if (initialText && newText) {
      if (initialText.enText.stringValue !== newText.enText.stringValue) {
        return true
      }
      if (initialText.translations.length !== newText.translations.length) {
        return true
      } else {
          for (let translationIndex = 0; translationIndex < initialText.translations.length; translationIndex++) {
            if (initialText.translations[translationIndex].stringValue !== newText.translations[translationIndex].stringValue) {
              return true
            }
          }
        }
    }
    return false 
  },

  isMtPending(translatableText, machineTranslations, isMtEnabled, isAlreadyPending) {
    return isMtEnabled && 
      !!translatableText?.enText?.stringValue?.trim() &&
      !translatableText?.enText.isMarkedForDeletion && (
        translatableText?.enText.isChanged || machineTranslations?.length < 1 || isAlreadyPending
      )
  },

  init() {
      this.parentObject = this  //Assign parentObject. Function syntax (rather than arrow syntax) is needed in this file for 'this' to work.
      delete this.Init          //Only allow init to run once.
      return this               //Gives back the object itself if instantiation is needed.
  }
}.init()