import { DocumentTypes_DB_PEnum, ProfileItemVisibility_DB_PEnum } from '../../../configs/pseudoEnums'
import { TranslatableText } from '../../../utility/TranslatableText'
import { removeObjectArrayElements, sortObjectArray } from '../../../utility/Utils'

// ** Initial State
const initialState = {
    mediaItems: [],
    currentIndex: 0,
    isMediaLoaded: false,
    bannerDestinationImages:[],
    showEditImageOverlaySaveToast: false,    //If the overlay is opened from the profileItem overlay it should show a save toast.
    fileUploadProgress: {}
}

export const getDefaultMediaItem = (mediaType = '') => {
  return {
    id: 0,
    blobId: Date.now(), //newUuid(),        //We need a GUID because this needs to be unique, because we store the blob directly from the UI (before we have generated a mediaItem.id [int] on the API side).
    name: TranslatableText.getNew(true),
    profileId: '',
    parentId: '',
    parentEntityType: '',
    mediaType: mediaType,
    documentType: DocumentTypes_DB_PEnum.none,
    blobRelativePath: '',     //Format: profileId/fileName (where fileName is mediaItem.id_originalFileName)
    localUrl: '',
    blobUrl: '',
    embedUrl: '',           //For embedded videos.
    azureContainerName: '',
    credit: '',
    fileName: '',
    fileType: '',
    sas: '',
    hasFileSizeWarning: false,
    fileSizeKb: 0,
    width: 0,
    height: 0,
    originalFileName: '',
    sequence: 0,
    hasAcceptedUsagePolicy: true,
    visibility: ProfileItemVisibility_DB_PEnum.publicAndTrade,
    uploadErrorMessage: '',
    label: TranslatableText.getNew(true),   //TODO: These TranslableText objects (label, description) are stored inline in the Media document, hence no id. But should we make this consistent and add an ID?
    description: TranslatableText.getNew(true),
    lastModified: '',
    isChanged: true,      //True if an item has been added, edited or deleted. If MediaItem (not blob) has changed (sequence, credit, name etc.) and DB needs to be updated. Use blobUrl to detect if blob exists in db.
    isMarkedForDeletion: false,        //True if an item is marked for deletion
    existsInDb: false,
    isRoomPlanImage: false,
    autoTags: [],
    manualTags: "",
    thumbnailUrl: '',
    thumbnailBlobRelativePath: ''
  }
}

const getUpdatedListAfterDb = (oldList, listChanges) => {
  //For each item in listChanges: 
  //if it is a delete, then delete the item from old list. 
  //If it is a change, update the item in old list.
  //If it is unchanged, leave the old item as is.

  const newList = []
  oldList?.forEach(item => {
    const changeItem = listChanges.find(x => x.id === item.id)
    if (changeItem) {
      if (!changeItem.isMarkedForDeletion) {
        newList.push({...changeItem, existsInDb: true, isChanged: false})
      }
    } else {
      newList.push(item)
    }
  })
  listChanges?.forEach(item => {
    if (!oldList?.find(x => x.id === item.id)) {
      newList.push({...item, existsInDb: true})
    }
  })
  return newList
}

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MEDIAITEMS':
      return { ...state, mediaItems: action.data }
    case 'DELETE_MEDIAITEM':
      state.mediaItems.splice(state.mediaItems.findIndex(x => x.id === action.data), 1)
      return {...state, mediaItems: state.mediaItems }
    case 'DELETE_MEDIAITEMS':
      const remaining = removeObjectArrayElements(state.mediaItems, 'id', action.data)
      return {...state, mediaItems: remaining }
    case 'SET_SELECTED_MEDIAITEMS_AFTER_DB_UPDATE':
      let newMediaItems = getUpdatedListAfterDb(state.mediaItems, action.data)
      newMediaItems = sortObjectArray(_.cloneDeep(newMediaItems), 'sequence', 'mediaType')
      return { ...state, mediaItems: newMediaItems }
    case 'RESET_MEDIAITEMS':
      return {...state, mediaItems: initialState.mediaItems}
    case 'SET_CURRENT_INDEX':
      return {...state, currentIndex: action.data}
    case 'SET_ISMEDIALOADED':
      return {...state, isMediaLoaded: action.data}
    case 'SET_DESTINATION_BANNER_IMAGES':
      return {...state, bannerDestinationImages: action.data }
    case 'SET_SHOW_EDIT_IMAGE_OVERLAY_SAVE_TOAST':
      return {...state, showEditImageOverlaySaveToast: action.data }
    case 'SET_MEDIA_UPLOAD_PERCENT':      
      return {...state, fileUploadProgress: {...state.fileUploadProgress, [action.data.mediaId]: action.data.filePercent} }
    default:
      return state
  }
}

export default mediaReducer
