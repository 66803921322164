import { TranslatableText } from '../../../utility/TranslatableText'
import { ProfileCategory_PEnum, ProfileTier_DB_PEnum, StatusNotification_PEnum } from '../../../configs/pseudoEnums'
import { isObjEmpty } from '../../../utility/Utils'
import { isAnyMtLanguages } from '../../../configs/profileConfig'

// ** Initial State
export const initialState = {
  adminUpdatedProfile: null,
  suggestions: [],
  suggestedDestinations: [],
  profilesInReview: [],
  profileSummaries: [],
  features: {
    featuresData: {
      activities: [],
      property: [],
      rooms: [],
      services: []
    },
    languages: [],
    iBrochureLanguages: [],
    friendly: [],
    specialInterests: [],
    tourOperatorTourAgentGuidance: [],
    suitableFor: [],
    weatherRequirement: [],
    cuisine: [],
    ambiance: [],
    participation: [],
    skills: [],
    venueEquipment: []
  },
  profile: {
    id: '',
    mapObjectId: null,
    accountIds: [],
    isDistributed: false,
    isEnabled: false,
    profileId: '',
    name: '',
    shortDescription: TranslatableText.getNew(false),
    richDescription: TranslatableText.getNew(false),
    travelInformation: {},        
    type: null,
    languageGuide:[{}],
    category: null,
    pin: { latitude: 0, longitude: 0, drivingLatitude: 0, drivingLongitude: 0 },
    streetAddress: '',
    postalCode: '',
    city: '',
    media: [],    //When we load a saved profile the media is passed as a child of profile, but we should never use profile.media in the UI.
    province: '',
    suburb: '',
    country: '',
    contacts: [],
    checkinTimeFrom: '',
    checkinTimeTo: '',
    checkoutTimeFrom: '',
    roomCount: 0,
    tentCount: 0,
    cabinCount: 0,
    seatCount: 0,
    maxGroupSize: 0,
    accommodationsCount: 0,
    restaurantsCount: 0,
    loungesCount: 0,
    terminalsCount: 0,
    shopsCount: 0,    
    accommodationsCount: 0,
    restaurantsCount: 0,
    loungesCount: 0,
    terminalsCount: 0,
    shopsCount: 0,
    destinationSize: '',
    population: '',
    destinationCurrency: '',
    starRating: 0,
    features: [], // On Wizard Publish will add English as a feature
    isChanged: false,
    isLoading: true,
    isCreated: false,
    isValidated: false,
    isReviewRequired: false,
    validationSucceeded: false,   //if isValidated==true && validationSucceeded==false then review is required.
    isPublished: false,
    isPublishing: false,
    isInReview: false,
    isPrivate: false,
    tier: ProfileTier_DB_PEnum.Basic,
    iBrochureLanguages: ["English"],
    tags: [],
    associatedItems: [],
    aliases: [],
    resetObject: null,
    qualityAssuranceBody: '',
    alternativeRating: '',
    minimumChildAge: 0,
    availableAccommodationOnsite: 0,
    availableAccommodationOffsite: 0,
    redirectUrl: '',
    dateCreated: null,
    links: {},
    affiliate: 'None', //Setting the Default affiliate to none.
    operatingHoursByDay: {
      isOpenPublicHolidays: false,
      isByAppointment: false
    },
    availableLanguages: [],
    officialLanguages: [],
    enabledDisabledProfile: {},
    upgradedDowngradedProfile: {},
    dmo: {
      activities: [],
      stays: [],
      destinations: [],
      polygon: {}
    },
    destinationPolygonId: null,
    destinationPolygonCentreLatitude: null,
    destinationPolygonCentreLongitude: null,
    userDestinations: null,
    selectedUserDestination: null,
    existsInCache: false,
    profileEditorErrors: {},
    showZoom: true,
    zoomTo: 0,
    minimumZoom: 0,
    maximumZoom: 0,
    //If they downgrade we cannot set isMachineTranslationsEnabled to false because if they click Upgrade again we need to re-show their MT langauges:
    isMtEnabled: (profile) => { return profile.isMachineTranslationsEnabled && profile.tier === ProfileTier_DB_PEnum.Professional && isAnyMtLanguages(profile.iBrochureLanguages) }
  },
  profileGrid: {
    currentPage: 1,
    rowsPerPage: 50,
    searchText: '',
    sortColumn: {
      sortColumnName: 'DateCreated',
      sortDirection: 'desc'
    },
    totalPage: 1,
    tierFilter: '',
    typeFilter: '',
    statusFilter: '',
    recordCount: 0,
    noRecords: false
  },
  profileEditor: {
    children: [],
    profileEditorImage: "",
    profileSliderImages: []
  },
  publishedProfile: {},
  deletedProfile: {},
  deletedProfileId: '',
  linkedScheduledDepartures: [],
  operators: [],
  statusNotification: StatusNotification_PEnum.None,
  isSavingButtonId: null,      //The ID of the SpinnerSaveButton that has been clicked. Set inside SpinnerSaveButton.
  isSaving: false              //Set to true once in the axios function. Not set in SpinnerSaveButton because validation logic may prevent axios function running which means the spinner would not disappear if validation fails (and there could be multiple conditional statements before the axios function).
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_PROFILE':
      return {
        ...state,
        profile: {
          ...state.profile,
          id: action.data.profile.id,
          profileId: action.data.profile.id,
          isCreated: action.data.profile.isCreated,
          isValidated: action.data.profile.isValidated,
          isPublished: action.data.profile.isPublished,
          sas: action.data.profile.sas,
          isChanged: false,
          dateCreated: action.data.profile.dateCreated,
          shortDescription: action.data.profile.shortDescription, 
          richDescription: action.data.profile.richDescription
        }
      }
    case 'SET_DELETED_PROFILE_ID':
      return { ...state, deletedProfileId: action.data }
    case 'SET_PROFILE_SUMMARIES':
      const gridChanges = {
        sortColumn: { sortColumnName: action.data.sortColumnName, sortDirection: action.data.sortDirection },
        totalPage: action.data.totalPage,
        currentPage: action.data.currentPage,
        rowsPerPage: action.data.rowsPerPage,
        tierFilter: action.data.tierFilter,
        typeFilter: action.data.typeFilter,
        statusFilter: action.data.statusFilter,
        recordCount: action.data.recordCount,
        noRecords: action.data.profileSummaries.length === 0
      }
      const newProfileGrid = { ...state.profileGrid, ...gridChanges }
      return { ...state, profileGrid: newProfileGrid, profileSummaries: action.data.profileSummaries}
    case 'SET_SEARCH_TEXT':
      return { ...state, profileGrid: { ...state.profileGrid, searchText: action.data } }
    case 'SET_PROFILE':
      return { ...state, 
        profile: { 
          ...action.data, 
          dmo: action.data.category === ProfileCategory_PEnum.dmo ? state.profile.dmo : {
            activities: [],
            stays: [],
            destinations: []
          }
        } 
  }
    case 'SET_NAME':
      return { ...state, profile: { ...state.profile, name: action.data, isChanged: true, isValidated: false } }
    case 'SET_DESCRIPTION':
      return { ...state, profile: { ...state.profile, shortDescription: action.data, isChanged: true } }
    case 'SET_TYPE':
      return { ...state, profile: { ...state.profile, type: action.data, isValidated: false } }
    case 'SET_CATEGORY':
      return { ...state, profile: { ...state.profile, category: action.data } }
    case 'SET_NAME_ADDRESS_LOCATION':
      const data = action.data
      return {
        ...state,
        profile: { ...state.profile, name: data.name, pin: data.pin, streetAddress: data.streetAddress, postalCode: data.postalCode, country: data.country, city: data.city, suburb: data.suburb, province: data.province, isChanged: true, isValidated: false }
      }
    case 'SET_PIN':
      return { ...state, profile: { ...state.profile, pin: action.data, isChanged: true, isValidated: false } }
    case 'SET_STREET_ADDRESS':
      return { ...state, profile: { ...state.profile, streetAddress: action.data, isChanged: true } }
    case 'SET_POSTAL_CODE':
      return { ...state, profile: { ...state.profile, postalCode: action.data, isChanged: true } }
    case 'SET_CITY':
      return { ...state, profile: { ...state.profile, city: action.data, isChanged: true } }
    case 'SET_PROVINCE':
        return { ...state, profile: { ...state.profile, province: action.data, isChanged: true } }
    case 'SET_SUBURB':
        return { ...state, profile: { ...state.profile, suburb: action.data, isChanged: true } }
    case 'SET_COUNTRY':
      return { ...state, profile: { ...state.profile, country: action.data, isChanged: true } }
    case 'SET_PROFILE_CONTACT':
      let found = false
      const existingItems = (!state.profile.contacts 
      || state.profile.contacts.length === 0) ? [] : state.profile.contacts.map((existingItem) => {
        if (existingItem.type === action.data.type && existingItem.fieldType === action.data.fieldType) {
          found = true
          return action.data
        }
        return existingItem
      })
      
      if (!found) {
        existingItems.push(action.data)
      }
      
      return {
        ...state,
        profile: {
          ...state.profile,
          contacts: existingItems,
          isChanged: true
        }
      }
    case 'DELETE_PROFILE_CONTACT':
      const contactsCopy = Object.assign([], state.profile.contacts)
      if (action.data.type && action.data.fieldType) {
        const findIndex = contactsCopy?.findIndex(x => x.type === action.data.type && x.fieldType === action.data.fieldType)
        if (findIndex >= 0) {
          contactsCopy.splice(findIndex, 1)
        }
      } else if (!action.data.fieldType && action.data.type) {
        const fields = contactsCopy.filter(y =>  y.type === action.data.type)
        fields.forEach(field => {
          const findIndex = contactsCopy?.findIndex(z => z.type === field.type && z.fieldType === field.fieldType)
          if (findIndex >= 0) {
            contactsCopy.splice(findIndex, 1)
          }
        })
      }
      return { ...state, profile: { ...state.profile, contacts: contactsCopy, isChanged: true } }
    case 'SET_PROFILE_CHECK_IN_FROM':
      return { ...state, profile: { ...state.profile, checkinTimeFrom: action.data, isChanged: true } }
    case 'SET_PROFILE_CHECK_IN_TO':
      return { ...state, profile: { ...state.profile, checkinTimeTo: action.data, isChanged: true } }
    case 'SET_PROFILE_CHECK_OUT_FROM':
      return { ...state, profile: { ...state.profile, checkoutTimeFrom: action.data, isChanged: true } }
      
    case 'SET_ACCOMMODATIONS_COUNT':
      return { ...state, profile: { ...state.profile, accommodationsCount: action.data, isChanged: true } }
    case 'SET_RESTAURANTS_COUNT':
      return { ...state, profile: { ...state.profile, restaurantsCount: action.data, isChanged: true } }
    case 'SET_LOUNGES_COUNT':
      return { ...state, profile: { ...state.profile, loungesCount: action.data, isChanged: true } }
    case 'SET_TERMINALS_COUNT':
      return { ...state, profile: { ...state.profile, terminalsCount: action.data, isChanged: true } }
    case 'SET_SHOPS_COUNT':
      return { ...state, profile: { ...state.profile, shopsCount: action.data, isChanged: true } }

    case 'SET_PROFILE_ROOM_COUNT':
      return { ...state, profile: { ...state.profile, roomCount: action.data, isChanged: true } }
    case 'SET_PROFILE_CABIN_COUNT':
      return { ...state, profile: { ...state.profile, cabinCount: action.data, isChanged: true } }
    case 'SET_PROFILE_TENT_COUNT':
      return { ...state, profile: { ...state.profile, tentCount: action.data, isChanged: true } }
    case 'SET_PROFILE_SEAT_COUNT':
      return { ...state, profile: { ...state.profile, seatCount: action.data, isChanged: true } }
    case 'SET_PROFILE_MAX_GROUP_SIZE':
      return { ...state, profile: { ...state.profile, maxGroupSize: action.data, isChanged: true } }
    case 'SET_PROFILE_MINIMUM_CHILD_AGE':
      return { ...state, profile: { ...state.profile, minimumChildAge: action.data, isChanged: true } }
    case 'SET_PROFILE_ACCOMMODATION_ON_SITE':
      return { ...state, profile: { ...state.profile, availableAccommodationOnsite: action.data, isChanged: true } }
    case 'SET_PROFILE_ACCOMMODATION_OFF_SITE':
      return { ...state, profile: { ...state.profile, availableAccommodationOffsite: action.data, isChanged: true } }
    case 'SET_PROFILE_STAR_RATING':
      return { ...state, profile: { ...state.profile, starRating: action.data, isChanged: true } }
    case 'SET_PROFILE_QUALITY_ASSURANCE_BODY':
      return { ...state, profile: { ...state.profile, qualityAssuranceBody: action.data, isChanged: true } }
    case 'SET_PROFILE_ALTERNATIVE_RATING':
      return { ...state, profile: { ...state.profile, alternativeRating: action.data, isChanged: true } }
    case 'SET_PROFILE_CAPACITY':
      return { ...state, profile: { ...state.profile, capacity: action.data, isChanged: true } }
    case 'SET_PROFILE_DIMENSIONS':
      return { ...state, profile: { ...state.profile, dimensions: action.data, isChanged: true } }
    case 'SET_PROFILE_IATA_CODE':
      return { ...state, profile: { ...state.profile, iataCode: action.data, isChanged: true } }
    case 'SET_PROFILE_ICAO_CODE':
      return { ...state, profile: { ...state.profile, icaoCode: action.data, isChanged: true } }
    case 'SET_PROFILE_INFRASTRUCTURE_SIZE':
      return { ...state, profile: { ...state.profile, infrastructureSize: action.data, isChanged: true } }
    case 'SET_FEATURES':
      return { ...state, profile: { ...state.profile, features: action.data, isChanged: true } }
    case 'RESET_PROFILE':
      return { ...state, profile: { ...initialState.profile, resetObject: new Date() } }
    case 'SET_IS_PUBLISHING':
      return { ...state, profile: { ...state.profile, isPublishing: action.data } }
    case 'SET_TIER':
      return { ...state, profile: { ...state.profile, tier: action.data } }
    case 'SET_VISIBILITY':
        return { ...state, profile: { ...state.profile, isPrivate: action.data } }
    case 'SET_WIZARD_SAVE_PAGE_NUMBER':
      return { ...state, profile: { ...state.profile, wizardSavePageNum: action.data, saveTrigger: new Date() } }
    case 'SET_LOAD_TRIGGER':
      return { ...state, profile: { ...state.profile, wizardSavePageNum: action.data, loadTrigger: new Date() } }
    case 'SET_IS_LOADING':
      return { ...state, profile: { ...state.profile, isLoading: action.data } }
    case 'SET_IS_CHANGED':
      return { ...state, profile: { ...state.profile, isChanged: action.data } }
    case 'SET_REDIRECT_URL':
      return { ...state, profile: { ...state.profile, redirectUrl: action.data } }
    case 'SET_ACTIVE_PROFILE_EDITOR_CHILDREN':
      return { ...state, profileEditor: { ...state.profileEditor, children: action.data } }
    case 'SET_PROFILE_EDITOR_IMAGE':
      return { ...state, profileEditor: { ...state.profileEditor, profileEditorImage: action.data } }
    case 'SET_PUBLISHED_PROFILE':
      return { ...state, publishedProfile: action.data }    
    case 'SET_PROFILE_OFFICIAL_LANGUAGES':
      return { ...state, profile: { ...state.profile, officialLanguages: action.data, isChanged: true } }
    case 'SET_PROFILE_POPULATION':
      return { ...state, profile: { ...state.profile, population: action.data, isChanged: true } }
    case 'SET_PROFILE_DESTINATION_SIZE':
      return { ...state, profile: { ...state.profile, destinationSize: action.data, isChanged: true } }
    case 'SET_PROFILE_DESTINATION_CURRENCY':
      return { ...state, profile: { ...state.profile, destinationCurrency: action.data, isChanged: true } }
    case 'SET_PROFILE_LANGUAGE_GUIDE':
      return { ...state, profile: { ...state.profile, languageGuide: action.data, isChanged: true } }    
    case 'SET_IBROCHURE_LANGUAGES':
      return { ...state, profile: { ...state.profile, iBrochureLanguages: action.data } }  //We don't set isChanged here because this happens on load. 
    case 'SET_PROFILE_TAGS':
      return { ...state, profile: { ...state.profile, tags: action.data, isChanged: true } }
    case 'SET_PROFILE_SEARCH_ALIASES':
      return { ...state, profile: { ...state.profile, aliases: action.data, isChanged: true } }
    case 'SET_PROFILE_TRANSLATABLE_TEXT_SHORT_DESCRIPTION':
      return { ...state, profile: { ...state.profile, shortDescription: action.data, isChanged: true } }
    case 'SET_PROFILE_TRANSLATABLE_TEXT_RICH_DESCRIPTION':
      return { ...state, profile: { ...state.profile, richDescription: action.data } }
    case 'SET_PROFILE_LINKS':
      return { ...state, profile: { ...state.profile, links: action.data, isChanged: true } }
    case 'SET_ASSOCIATED_ITEMS':
      return { ...state, profile: { ...state.profile, associatedItems: action.data, isChanged: true } }
    case 'SET_PROFILE_AFFILIATE':
      return { ...state, profile: { ...state.profile, affiliate: action.data, isChanged: true } }
    case 'SET_PROFILE_SLIDER_IMAGES':
      return { ...state, profileEditor: { ...state.profileEditor, profileSliderImages: action.data } }
    case 'SET_OPERATING_HOURS_BY_DAY':
      return { ...state, profile: { ...state.profile, operatingHoursByDay: action.data } }
    case 'GET_ALL_FEATURES':
      return { ...state, features: action.data }
    case 'SET_PROFILE_FEATURE_LANGUAGES':
      return { ...state, profile: { ...state.profile, availableLanguages: action.data } }
    case 'GET_PROFILE_AUTOCOMPLETE': 
      return { ...state, suggestions: action.data }
    case 'CLEAR_PROFILE_AUTOCOMPLETE': 
      return { ...state, suggestions: [] }
    case 'GET_PROFILES_IN_REVIEW':
      return { ...state,  profilesInReview: action.data }
    case 'ENABLE_DISABLE_PROFILE':
      return { ...state,  enabledDisabledProfile: action.data}
    case 'CLEAR_ENABLE_DISABLE_PROFILE':
      return { ...state,  enabledDisabledProfile: {}}
    case 'SET_ITEMS_FOR_DMO_PROFILE':
      const { type, list } = action.data
      return { ...state,  profile: { ...state.profile, dmo: {...state.profile.dmo, [`${type}`]: list} }}
    case 'SET_DMO_POLYGON':
      return { ...state,  profile: { ...state.profile, dmo: {...state.profile.dmo, polygon: action.data} }}
    case 'SET_POLYGON_ASSOCIATED_ITEM':
        return { ...state, profile: { ...state.profile, destinationPolygonId: action.data, isChanged: true } }
    case 'SET_POLYGON_CENTER_COORDINATES':
        return {...state, profile: {...state.profile, destinationPolygonCentreLatitude: action.data.latitude, destinationPolygonCentreLongitude: action.data.longitude, isChanged: true }}
    case 'SET_USER_DESTINATIONS':
      return { ...state, profile: { ...state.profile, userDestinations: action.data, isChanged: true } }
    case 'SET_SELECTED_USER_DESTINATION':
      return { ...state, profile: { ...state.profile, selectedUserDestination: action.data, isChanged: true } }
    case 'UPGRADE_DOWNGRADE_PROFILE':
      return { ...state,  upgradedDowngradedProfile: action.data}
    case 'CLEAR_UPGRADE_DOWNGRADE_PROFILE':
      return { ...state,  upgradedDowngradedProfile: {}}
    case 'SET_PROFILE_TIER':
        const summaries = Object.assign([], state.profileSummaries)
        const profileSummary = summaries.find(p => p.id === action.data.profileId)
        if (profileSummary) {
          profileSummary.tier = action.data.tier
        }
        return {...state, profileSummaries: summaries}
    case 'SET_DELETED_PROFILE':
      return { ...state, deletedProfile: action.data }
    case 'REMOVE_PROFILE':
      const profileSummaries = Object.assign([], state.profileSummaries)
      const summary = profileSummaries.find(p => p.id === action.data)
      if (profileSummaries.indexOf(summary) > -1) {
        profileSummaries.splice(profileSummaries.indexOf(summary), 1)
      }
      return { ...state, profileSummaries: profileSummaries }
    case 'SET_IS_MACHINE_TRANSLATIONS_ENABLED':
      return { ...state, profile: { ...state.profile, isMachineTranslationsEnabled: action.data, isChanged: true } }
    case 'SET_EXISTS_IN_CACHE':
        return { ...state, profile: { ...state.profile, existsInCache: action.data } }
    case 'SET_OPERATORS':
      return { ...state, operators: action.data }
    case 'SET_OPERATOR_TRANSFER_ACCOUNTS':
      return { ...state, transferOwnershipAccounts: action.data }
    case 'SET_SUGGESTED_DESTINATIONS':
      return {...state, suggestedDestinations: action.data }
    case 'SET_SELECTED_DESTINATION':
      return {...state, profile: {...state.profile, associatedItems: action.data }}
    case 'SET_PROFILE_STATUS':
      return { ...state, profile: { ...state.profile, status: action.data, isChanged: true } }
    case 'SET_MAP_OBJECT_ID_FOR_PROFILE':
      return { ...state, profile: { ...state.profile, mapObjectId: action.data } }
    case 'UPDATE_PROFILE_STATUS_IN_GRID':
      const profileList = Object.assign([], state.profileSummaries)
      const matchingProfile = profileList.find(p => p.id === action.data.id)
      if (profileList.indexOf(matchingProfile) > -1) {
        matchingProfile.status = action.data.status
      }
      return {...state, profileSummaries: profileList}
    case 'SET_STATUS_NOTIFICATION':
      return { ...state, profile: { ...state.profile, statusNotification: action.data} }
    case 'UPDATE_PROFILE_SUMMARY' :
      const profileSummariesCopy = Object.assign([], state.profileSummaries)
      const foundProfileSummary = profileSummariesCopy.find(p => p.id === action.data.profileId)
      foundProfileSummary.statusNotification = action.data.statusNotification
      return {...state, profileSummaries: profileSummariesCopy}
    case 'SET_IS_PUBLISHED':
      return { ...state, profile: { ...state.profile, isPublished: action.data } }
    case 'PROFILE_FIELD_VALIDATION_FAILURE':
      const errorsCopy = Object.assign({}, state.profileEditorErrors)
      if (!isObjEmpty(action.data)) {
        errorsCopy[action.data?.fieldId] = action.data?.error
      }
      return { ...state,  profile: { ...state.profile, profileEditorErrors: errorsCopy}}
    case 'SET_MAXIMUM_ZOOM':
      return { ...state,  profile: { ...state.profile, maximumZoom: action.data}}
    case 'SET_MINIMUM_ZOOM':
      return { ...state,  profile: { ...state.profile, minimumZoom: action.data}}
    case 'SET_ZOOM_TO':
      return { ...state,  profile: { ...state.profile, zoomTo: action.data}}
    case 'SET_SHOW_ZOOM':
      return { ...state,  profile: { ...state.profile, showZoom: action.data}}
    case 'SET_IS_SAVING_BUTTON_ID':
      return { ...state, isSavingButtonId: action.data }
    case 'SET_IS_SAVING':
        return { ...state, isSaving: action.data }
    case 'CLEAR_IS_SAVING':
      return { ...state, isSavingButtonId: null, isSaving: false }  //Resets both spinner button properties for convenience.
    case 'SET_ADMIN_UPDATED_PROFILE':
      return { ...state, adminUpdatedProfile: action.data }
    case 'SET_AFFILIATE':
        return { ...state,  profile: { ...state.profile, affiliate: action.data}}
    case 'SET_AFFILIATE_CODE':
      return { ...state,  profile: { ...state.profile, affiliateCode: action.data}}
    case 'SET_PRINCIPAL_ID':
      return { ...state,  profile: { ...state.profile, principalId: action.data}}
    default:
      return state
  }
}

export default profileReducer