import { accommodationCategoryArray, activityCategoryArray, attractionsCategoryArray, informationServicesCategoryArray, infrastructureCategoryArray, restaurantCategoryArray, venuesCategoryArray, destinationCategoryArray, otherCategoryArray } from './profileConfig'
import { ProfileType_PEnum, ProfileItemType_PEnum, sleepingArrangementTypes, MediaType_PEnum, Selector_PEnum, ProfileCategory_PEnum } from './pseudoEnums'

import activityPin from '../assets/images/icons/profile-type-pin-icons/icon_things-to-do.svg'
import restaurantPin from '../assets/images/icons/profile-type-pin-icons/icon_food-and-beverage.svg'
import accommodationPin from '../assets/images/icons/profile-type-pin-icons/icon_accommodation.svg'
import attractionsPin from '../assets/images/icons/profile-type-pin-icons/icon_attractions.svg'
import infrastructurePin from '../assets/images/icons/profile-type-pin-icons/icon_infrastructure.svg'
import destinationPin from '../assets/images/icons/profile-type-pin-icons/icon_destination.svg'
import venuePin from '../assets/images/icons/profile-type-pin-icons/icon_venues_bw.svg'

import profileMarker from '../assets/images/icons/profile-marker.svg'

export const profileTypeMap = {  
    [ProfileType_PEnum.accommodation]: {
        descHeaderType: 'property',
        imageHeaderType: 'property',
        profileTypeCategories: accommodationCategoryArray,
        profileLocationIcon: accommodationPin,
        descriptionExample: (
            <span>Set in the vibrant heart of Cape Town’s Waterfront, surrounded by restaurants, shopping, art and entertainment, on the doorstep of Table Mountain; and within easy reach of spectacular beaches.</span>
        ),
        profileItems: {
            name: "Sleeping arrangements",
            description: "Tell us about your rooms, units, or other sleeping arrangements.",
            navText: "Add sleeping arrangements",
            navId: "finaliseAddSleepingArrangementButton",
            profileItemTypes: sleepingArrangementTypes,
            typeLabelLower: "sleeping arrangement"
        },  
        titleOverlay: "What does your room type look like?",
        showSelectors:[  
            Selector_PEnum.qualityAssurance,
            Selector_PEnum.numberOfRooms,
            Selector_PEnum.minChildAge,
            Selector_PEnum.checkInTime,
            Selector_PEnum.friendly,
            Selector_PEnum.specialInterests,
            Selector_PEnum.tourGuidance,
            Selector_PEnum.sleepingArrangement,
            Selector_PEnum.facilities,
            Selector_PEnum.roomPlan,
            Selector_PEnum.tags,
            Selector_PEnum.languageSpoken
        ],  
        coverImageName: 'Why stay here',
        coverImageMediaType: MediaType_PEnum.whyStayHereCoverImage,
        categoryOverride: {
            [ProfileCategory_PEnum.mobileSafari]: {
                informationHeader: 'Camp information',
                informationHeadingSubText: 'Fill in the basics with your phone number and email address.',
                imageHeaderSubText: 'Photos are a great way to showcase your product offering. Choose up to {maxFiles} photos to represent your online presence. Drag images to reorder as you prefer. ',
                descHeaderType: 'mobile safari',
                imageHeaderType: 'mobile safari',
                phoneLabel: 'Telephone number',
                phoneErrorMessage: "Please enter a telephone or reservation telephone number.",
                locationMap: { mobileSafari: true },
                descriptionExample: (<>
                    <span>In addition to ticking off the Big Five and nearly 600 bird species, Botswana offers an unparalleled game experience and natural diversity. Choosing a mobile safari over a traditional lodge is a truly immersive and life-changing experience that captures the essence of the African camping safari.</span>
                    <br /><br />
                    <span>Explore remote islands on foot, boat up to elephant herds, and zoom across floodplains in a custom 4x4 in search of game. Watch birds flit across china-blue skies in the afternoons.</span>
                    <br /><br />
                    <span>Prior to your departure, we spend time learning about your interests. So, whether you're a wildlife photographer or birder - or simply need to disconnect from the world. There isn't a better way to experience incredible game viewing in a truly wild environment than on a mobile safari.</span>
                </>),
                 showSelectors:[  
                    Selector_PEnum.qualityAssurance,
                    Selector_PEnum.numberOfTents,
                    Selector_PEnum.minChildAge,
                    Selector_PEnum.friendly,
                    Selector_PEnum.specialInterests,
                    Selector_PEnum.tourGuidance,
                    Selector_PEnum.participation,
                    Selector_PEnum.sleepingArrangement,
                    Selector_PEnum.languageSpoken
                ],
                titleOverlay: 'What does your sleeping arrangement look like?'
            },
            [ProfileCategory_PEnum.boat]: {
                informationHeadingSubText: 'Fill in the basics with your phone number and email address.',
                imageHeaderSubText: 'Photos are a great way to showcase your product offering. Choose up to {maxFiles} photos to represent your online presence. Drag images to reorder as you prefer. ',
                descHeaderType: 'boat',
                imageHeaderType: 'boat',
                showSelectors:[  
                    Selector_PEnum.qualityAssurance,
                    Selector_PEnum.numberOfCabins,
                    Selector_PEnum.minChildAge,
                    Selector_PEnum.friendly,
                    Selector_PEnum.specialInterests,
                    Selector_PEnum.tourGuidance,
                    Selector_PEnum.sleepingArrangement,
                    Selector_PEnum.facilities,
                    Selector_PEnum.roomPlan,
                    Selector_PEnum.tags,
                    Selector_PEnum.languageSpoken
                ],  
                facilityLabel: 'Cruise facilities',
                accommodationFacilityLabel: "Cabin facilities",
                descriptionExample: (
                    <span>Taking her passengers to ports all over the world, the Brilliance of the Seas offers a wide variety of itineraries. Onboard activities and experiences include dance classes, scrapbooking, game shows, pool parties, outdoor movie nights, and shopping, while guests are accommodated in comfortable cabins arranged in various configurations. The ship offers an array of dining and imbibing options, including bars, lounges, coffee shops, restaurants, and fast food outlets.</span>
                )
            },
            [ProfileCategory_PEnum.rail]: {
                informationHeadingSubText: 'Fill in the basics with your phone number and email address.',
                imageHeaderSubText: 'Photos are a great way to showcase your product offering. Choose up to {maxFiles} photos to represent your online presence. Drag images to reorder as you prefer. ',
                descHeaderType: 'train',
                imageHeaderType: 'train',
                showSelectors:[  
                    Selector_PEnum.qualityAssurance,
                    Selector_PEnum.numberOfCabins,
                    Selector_PEnum.minChildAge,
                    Selector_PEnum.friendly,
                    Selector_PEnum.specialInterests,
                    Selector_PEnum.tourGuidance,
                    Selector_PEnum.sleepingArrangement,
                    Selector_PEnum.facilities,
                    Selector_PEnum.roomPlan,
                    Selector_PEnum.tags,
                    Selector_PEnum.languageSpoken
                ],  
                facilityLabel: 'Train facilities',
                accommodationFacilityLabel: "Cabin facilities",
                descriptionExample: (
                    <>
                    <span>Step aboard South America's first luxury sleeper train for an unparalleled journey across the Andean plains on board the region's first luxury sleeper train. Travel in sumptuous style between Cusco, Puno, and Arequipa on a one-and two-night adventure, alighting to explore spectacular sights such as Lake Titicaca and the Sumbay caves.</span>
                    <br /><br />
                    <span>On board, every detail conjures up the landscape through which the train passes, from subtle takes Peruvian motifs to hand-loomed textiles. Enjoy enticing local specialties in our dining cars, delicious cocktails in the lounge car, and indulgent treatments in our spa car.</span>
                    </>
                )
            },
            [ProfileCategory_PEnum.vehicle] : {
                descriptionExample: (<>For those who treasure creature comforts and like camping in style, the Maui Motorhome range is an excellent choice. Campervans are built on quality European Mercedes or VW chassis less than 2.5 years old, so they drive as well as they look. This design combines space, comfort, and style, essentially creating a room where guests can live. In addition to warm neutral colors, reading spotlights, and roomy living areas, the Maui range offers the ultimate in campervan holidays.</>),
                descHeaderType: 'vehicle',
                languageHeadingStep: 'Languages spoken by your service offering',
                informationHeadingSubText:'Fill in the basics with your phone number and email address.',
                phoneLabel:'Telephone number',
                phoneErrorMessage: "Please enter a telephone or reservation telephone number.",
                imageHeaderType: 'vehicle ',
                imageHeaderSubText:'Photos are a great way to showcase your product offering. Choose up to {maxFiles} photos to represent your online presence. Drag images to reorder as you prefer. ',
                showSelectors:[  
                    Selector_PEnum.qualityAssurance,
                    Selector_PEnum.minChildAge,
                    Selector_PEnum.friendly,
                    Selector_PEnum.specialInterests,
                    Selector_PEnum.tourGuidance,
                    Selector_PEnum.participation,
                    Selector_PEnum.tags,
                    Selector_PEnum.sleepingArrangement,
                    Selector_PEnum.capacityAndDimensions,
                    Selector_PEnum.languageSpoken
                ]
            },
            [ProfileCategory_PEnum.camp]: {
                descriptionExample: (<>
                <span>A prime river location on the banks of the South Luangwa River, Chamilandu Bushcamp is one of Zambia's finest river camps. There is nothing more luxurious than the four-poster beds in the stilted thatch bungalows offered by Chamilandu. In addition to having only three ‘suites’ in the entire camp, this camp is well-known for its exclusivity, guaranteeing you will have an intimate bush experience whatever the time of year.</span>
                <br/><br/>
                <span>As with most camps in the region, Chamilandu is open between June and November, when walking safaris are most popular. Even when you’re not on a drive or walking in the surrounding grasslands you are bound to see many animals. Its charm lies in its proximity to the action: watching game from your bed or enjoying a sundowner from the bathtub while sipping a hot coffee is a must-do experience at Chamilandu.</span>
                </>)
            }
        }
    },
    [ProfileType_PEnum.activity]: {
        descHeaderType: 'activity',
        imageHeaderType: 'activity',
        profileTypeCategories: activityCategoryArray,
        profileLocationIcon: activityPin,
        descriptionExample: (<span>If you ever have the chance to view Victoria Falls, it would be a life-changing experience and the best way to experience them would be on a guided tour. Learn how the falls formed and what the local customs and traditions are surrounding these natural wonders. In addition to these tours, you will also likely have the opportunity to visit the local art and craft center after the tour.</span>),   
        informationHeadingSubText: 'Fill in the basics with your phone number and email address, along with the operating hours.',
        profileItems: {
            name: "Activity options",
            description: "Tell us about your various options.",
            navText: "Add options",
            navId: "finaliseAddActivityButton",
            profileItemTypes: [ProfileItemType_PEnum.activity],
            typeLabelLower: "activity option",
            profileItemHeader: "activity option"
        },
        showSelectors:[  
            Selector_PEnum.qualityAssurance,
            Selector_PEnum.maxGroupSize,
            Selector_PEnum.minChildAge,
            Selector_PEnum.operatingHours,
            Selector_PEnum.requirements,
            Selector_PEnum.friendly,
            Selector_PEnum.specialInterests,
            Selector_PEnum.tourGuidance,
            Selector_PEnum.tags,
            Selector_PEnum.inclusion,
            Selector_PEnum.languageSpoken
        ],
        titleOverlay: 'What does your option look like?',
        coverImageName: 'Why do this',
        coverImageMediaType: MediaType_PEnum.whyDoThisCoverImage
    },
    [ProfileType_PEnum.restaurant]: {
        descHeaderType: 'restaurant',
        imageHeaderType: 'restaurant',
        informationHeadingSubText:'Fill in the basics with your phone number and email address, along with the operating hours.',
        profileTypeCategories: restaurantCategoryArray,
        profileLocationIcon: restaurantPin,
        descriptionExample: (
            <>
            <span>The restaurant and menus at the Black Olive are designed to encourage conversation, whether it is a casual meal, a business lunch, or a leisurely dining event.</span>
            <br/><br/><span>You can enjoy our waterfront views, starry nights, and cool breezes on our alfresco balcony, or dine inside in the comfort of air conditioning. </span>
            <br/><br/><span>We are pleased to say that our restaurant is highly appreciated not just by the town's residents but also by travelers and diners from Cape Town and the surrounding areas.</span>
          </>),
        profileItems: {
            name: "Available spaces",
            description: "Tell us about your various spaces. ",
            navText: "Add space",
            navId: "finaliseAddAvailableSpacesButton",
            profileItemTypes: [ProfileItemType_PEnum.restaurant],
            typeLabelLower: "available space",
            titleDescription:"Add your various spaces, for example a private dining room with enticing descriptions and images.",
            profileItemHeader: "available space"
        },
        showSelectors:[  
            Selector_PEnum.qualityAssurance,
            Selector_PEnum.numberOfSeats,
            Selector_PEnum.minChildAge,
            Selector_PEnum.operatingHours,
            Selector_PEnum.friendly,
            Selector_PEnum.restaurantStyle,
            Selector_PEnum.tags,
            Selector_PEnum.languageSpoken
        ],
        titleOverlay: 'What does your space look like?',
        coverImageName: 'Why eat here',
        coverImageMediaType: MediaType_PEnum.whyEatHereCoverImage
    },
    [ProfileType_PEnum.attraction]: {
        descHeaderType: 'attraction',
        imageHeaderType: 'attraction',
        profileTypeCategories: attractionsCategoryArray,
        profileLocationIcon: attractionsPin,
        informationHeadingSubText:'Fill in the basics with your phone number and email address, along with the operating hours.',
        descriptionExample: (<>
            <span>{`While it is called the Leaning Tower or Tower of Pisa, it was never used to defend the city; instead,it serves as a bell tower for the religious complex in Duomo Square.`}</span>  <br /> 
            <span>
              {`With its seven bells - one for each musical note - the largest of which weighs three and a half tons - it played an important role both in `}
              {`human and divine timekeeping. The building is known throughout the world for its beauty, its extraordinary `}
              {`tilt, which makes it an authentic miracle of statics, and its location in the world-famous `}
              {`Piazza dei Miracoli, of which it is undoubtedly the crown jewel. Because of this, it's considered one `}
              {`of the 7 Wonders of the World.`}
            </span>
          </>),
        phoneLabel:'Telephone number',
        phoneErrorMessage: "Please enter a telephone or reservation telephone number.",
        profileItems: {
            name: "Activity options",
            description: "Tell us about your various options.",
            navText: "Add options",
            navId: "finaliseAddOptionButton",
            profileItemTypes: [ProfileItemType_PEnum.activity],
            typeLabelLower: "activity option",
            profileItemHeader: "activity option"
        },
        phoneLabel:'Telephone number',
        phoneErrorMessage: "Please enter a telephone or reservation telephone number.",
        showSelectors:[  
            Selector_PEnum.qualityAssurance,
            Selector_PEnum.maxGroupSize,
            Selector_PEnum.minChildAge,
            Selector_PEnum.friendly,
            Selector_PEnum.specialInterests,
            Selector_PEnum.operatingHours,
            Selector_PEnum.requirements,
            Selector_PEnum.tourGuidance,
            Selector_PEnum.tags,
            Selector_PEnum.inclusion,
            Selector_PEnum.languageSpoken
        ],
        titleOverlay: 'What does your activity option look like?',

        coverImageName: 'Why visit here',
        coverImageMediaType: MediaType_PEnum.whyVisitHereCoverImage
    },
    [ProfileType_PEnum.information]: {
        descHeaderType: 'information / service',
        descSubText: 'Start by telling us the name and description of your service.',
        imageHeaderType: 'service',
        imageHeaderSubText: 'Photos are a great way to showcase your product offering. Choose up to {maxFiles} photos to represent your online presence. Drag images to reorder as you prefer.',
        profileTypeCategories: informationServicesCategoryArray,
        profileLocationIcon: '',
        descriptionExample: (<>
            <span>{`In essence, meet and greet airport services help travelers breeze through all airport formalities with ease and comfort. ABC Safaris guarantees all of that and more. The airport Meet & Greet service we offer is more adaptive to the dynamic challenges that come with modern-day international travel. We're here to offer you the absolute best service, comfort, and luxury on your upcoming trips.`}
            </span>
        </>),
        languageHeadingStep: 'Languages spoken by your service offering',
        informationHeadingSubText: 'Fill in the basics with your phone number and email address.',
        phoneLabel:'Telephone number',
        phoneErrorMessage: "Please enter a telephone or reservation telephone number.",
        profileItems: {
            name: "Available options",
            description: "Tell us about your various options.",
            navText: "Add available options",
            navId: "finaliseAddAvailableOptionButton",
            profileItemTypes: [ProfileItemType_PEnum.option],
            typeLabelLower: "available option",
            profileItemHeader: "option"
        },
        phoneLabel:'Telephone number',
        phoneErrorMessage: "Please enter a telephone or reservation telephone number.",
        titleOverlay: 'What do your available options look like?',
        showSelectors:[
            Selector_PEnum.skills,
            Selector_PEnum.specialInterests,
            Selector_PEnum.inclusion,
            Selector_PEnum.tags,
            Selector_PEnum.languageSpoken
        ],
        coverImageName: 'Why do this',
        coverImageMediaType: MediaType_PEnum.whyVisitHereCoverImage
    },
    [ProfileType_PEnum.infrastructure]: {
        descHeaderType: 'infrastructure',
        imageHeaderType: 'infrastructure',
        profileTypeCategories: infrastructureCategoryArray,
        profileLocationIcon: infrastructurePin,
        informationHeadingSubText:'Fill in the basics with your phone number and email address, along with the operating hours.',
        descriptionExample: (<>
            <span>Known as the Port of Cape Town, the Cape Town harbour lies between the Cape Peninsula and the west coast of Africa. It is one of the busiest ports in South Africa and handles the freshest fruit due to its location along one of the busiest commerce routes in the world. Durban is the port that handles most containers.</span>
            <br /> <br />
            <span>The port also has important repair and maintenance facilities used by a number of large fishing fleets and parts of the West African oil industry. Due to the numerous tourist attractions offered by the Cape and its surrounding region, many cruise ships also dock in the port.</span>
        </>),
        profileItems: {
            name: "Available spaces",
            description: "Tell us about your various spaces.",
            navText: "Add space",
            profileItemTypes: [ProfileItemType_PEnum.spaces],
            typeLabelLower: "Available space",
            profileItemHeader: "Space",
            typeLabelButton: "Add space",
            titleDescription: 'Add your various spaces with enticing descriptions and images'

        },  
        titleOverlay: "What does your space look like?",
        showSelectors:[  
            Selector_PEnum.numberOfAccommodations,
            Selector_PEnum.numberOfRestaurants,
            Selector_PEnum.numberOfLounges,
            Selector_PEnum.numberOfTerminals,
            Selector_PEnum.numberOfShops,
            Selector_PEnum.operatingHours,
            Selector_PEnum.airportCode,
            Selector_PEnum.tags,
            Selector_PEnum.languageSpoken
        ],  
        coverImageName: 'Why stay here',
        coverImageMediaType: MediaType_PEnum.whyStayHereCoverImage
    },
    [ProfileType_PEnum.destination]: {
        descHeaderType: 'attraction',
        imageHeaderType: 'destination',
        phoneLabel: 'Telephone number',
        altPhoneLabel: 'Alternative',
        phoneErrorMessage: "Please enter a telephone or reservation telephone number.",
        informationHeadingSubText:'Fill in the basics with your phone number and email address.',
        imageHeaderSubText: "Photos are a great way to showcase your product offering. Choose up to {maxFiles} photos to represent your online presence. Drag images to reorder as you prefer. ",
        profileTypeCategories: destinationCategoryArray,
        profileLocationIcon: destinationPin,
        locationMap: { destination: true },
        descriptionExample: (<>
            <span>Set at the confluence of the Indian and Atlantic oceans and backed by the iconic Table Mountain, the bustling city of Cape Town presents an alluring combination of drawcards. Incredible powdery white beaches, highly acclaimed wine routes, and astonishingly beautiful mountain trails surround a cosmopolitan hub. This diverse metropolis is filled with superb shops and restaurants, extraordinary heritage monuments, a multitude of entertainment venues, and a spectacular, chic waterfront harbour.</span>
            <br /><br />
            <span>Highlights include the Zeitz Museum of Contemporary African Art; Table Mountain's jaw-dropping views; Boulders Beach, where penguins might swim right up to you in the warm Indian ocean waters; the Kirstenbosch Botanical Gardens, where you can walk along a treetop canopy among incredible native flora or watch an open-air concert at the base of the mountain; Robben Island, where visitors can see the cell where Nelson Mandela was imprisoned for 27 years; and many more.</span>
            </>),
        profileItems: {
            name: "Attractions",
            description: "Add your various attractions with enticing descriptions and images.",
            navText: "Add Attraction",
            profileItemTypes: [ProfileItemType_PEnum.attraction],
            typeLabelLower: "attraction"
        },  
        titleOverlay: "Describe your attraction",
        showSelectors:[
            Selector_PEnum.languageGuide,
            Selector_PEnum.demographic
        ],
        coverImageName: '',
        coverImageMediaType: '',
        categoryOverride: {
            [ProfileCategory_PEnum.dmo]: {
                descHeaderType: 'destination',
                locationMap: { dmo: true },
                descriptionExample: (<>
                    <span>Located in South Africa’s beautiful Eastern Cape Province, Kariega Private Game Reserve lies in the upper reaches of the beautiful Kariega River Valley.</span>
                    <br /><br />
                    <span>Stretching for over 10 000 hectares, the landscape of the reserve features impressive vistas of forest, savannah grasslands scattered with abundant wildlife, and encompassing two rivers: the Kariega and the Bushmans rivers.</span>
                    <br /><br />
                    <span>Visitors can spot the Big Five as well as a variety of other animals including giraffe, eland, zebra, wildebeest, and an array of bird species; paddle a kayak, jump on a game drive or a river cruise for a close encounter with the reserve’s plentiful wildlife.</span>
                </>),
                showSelectors:[  
                    Selector_PEnum.languageGuide,
                    Selector_PEnum.demographic
                ]
            }
        }
    },
    
    [ProfileType_PEnum.venues]: {
        descHeaderType: 'Venues',
        imageHeaderType: 'property',
        profileTypeCategories: venuesCategoryArray,
        profileLocationIcon: venuePin,
        informationHeadingSubText:'Fill in the basics with your phone number and email address.',
        descriptionExample: (<>
            <span>Less than an hour from Cape Town City Centre and Cape Town International Airport, located in the heart of the Cape Winelands, The Royal Orchid Cape Town brings you exquisite conferencing facilities with a multitude of fun breakaways and team-building activities right on your doorstep.</span>
            <br /> <br />
            <span>Think wide-open spaces surrounded by majestic mountains, wholesome food celebrating the home-grown bounty of The Royal Orchid, on-site chefs who can cater to your needs, and a great variety of luxury farm accommodation suited for groups big and small.</span>
            <br /> <br />
            <span>We understand the need for results-driven conferencing and that’s why we’ve made sure our conferencing venues are filled with plenty of natural light to keep the creative juices flowing so the team can be as productive as ever.</span>
        </>),
        profileItems: {
            venues: {
                name: "Venue options",
                description: "Tell us about your venue options.",
                navText: "Add venues",
                navId: "finaliseAddVenueButton",
                profileItemTypes: [ProfileItemType_PEnum.venue],
                typeLabelLower: "venue",
                titleOverlay: "What does your venue look like?"
            },
            sleepingArrangements: {
                name: "Sleeping arrangements",
                description: "Tell us about your rooms, units, or other sleeping arrangements.",
                navText: "Add sleeping arrangements",
                navId: "finaliseAddSleepingArrangementButton",
                profileItemTypes: sleepingArrangementTypes,
                typeLabelLower: "sleeping arrangement",
                titleOverlay: "What does your room type look like?"
            }},  
        showSelectors: {
            venues: [
                Selector_PEnum.numberOfRooms,
                Selector_PEnum.minChildAge, 
                Selector_PEnum.qualityAssurance,
                Selector_PEnum.languageSpoken,
                Selector_PEnum.friendly,
                Selector_PEnum.venueDetails,
                Selector_PEnum.venueEquipment,
                Selector_PEnum.availableAccommodationOnsite,
                Selector_PEnum.availableAccommodationOffsite,
                Selector_PEnum.tags
            ],
            sleepingArrangements: [
                Selector_PEnum.sleepingArrangement,
                Selector_PEnum.numberOfRooms,
                Selector_PEnum.minChildAge,
                Selector_PEnum.languageSpoken,
                Selector_PEnum.friendly,
                Selector_PEnum.qualityAssurance,
                Selector_PEnum.venueEquipment,
                Selector_PEnum.availableAccommodationOnsite,
                Selector_PEnum.availableAccommodationOffsite,
                Selector_PEnum.roomPlan,
                Selector_PEnum.tags
            ]
        },  
        coverImageName: 'Why conference here',
        coverImageMediaType: MediaType_PEnum.whyConferenceHereCoverImage
    },

    [ProfileType_PEnum.other]: {
        descHeaderType: 'product offering',
        languageHeadingStep: 'Languages spoken at your product offering',
        imageHeaderType: 'product offering',
        phoneLabel: 'Telephone number',
        phoneErrorMessage: "Please enter a telephone or reservation telephone number.",
        informationHeadingSubText:'Fill in the basics with your phone number and email address.',
        imageHeaderSubText: "Photos are a great way to showcase your product offering. Choose up to {maxFiles} photos to represent your online presence. Drag images to reorder as you prefer. ",
        profileTypeCategories: otherCategoryArray,
        profileLocationIcon: destinationPin,
        profileItems: {
            name: "Available options",
            description: "Tell us about your various options.",
            navText: "Add option",
            profileItemTypes: [ProfileItemType_PEnum.option],
            typeLabelLower: "option"
        },  
        titleOverlay: "Describe your option",
        showSelectors:[
            Selector_PEnum.languageSpoken,
            Selector_PEnum.skills,
            Selector_PEnum.specialInterests,
            Selector_PEnum.inclusion,
            Selector_PEnum.tags
        ],
        coverImageName: 'Why do this',
        coverImageMediaType: MediaType_PEnum.whyDoThisCoverImage
    },
    default: {
        descHeaderType: "",
        imageHeaderType: "",
        profileTypeCategories: [],
        profileLocationIcon: profileMarker,
        descriptionExample: (''),
        profileItems: {
            name: "",
            description: "",
            navText: "",
            profileItemTypes: [],
            typeLabelLower: "",
            typeLabelPlural: ""
        },
        showSelectors:[  
            Selector_PEnum.qualityAssurance,
            Selector_PEnum.numberOfRooms,
            Selector_PEnum.maxGroupSize,
            Selector_PEnum.numberOfSeats,
            Selector_PEnum.minChildAge,
            Selector_PEnum.checkInTime,
            Selector_PEnum.operatingHours,
            Selector_PEnum.requirements,
            Selector_PEnum.skills,
            Selector_PEnum.friendly,
            Selector_PEnum.restaurantStyle,
            Selector_PEnum.specialInterests,
            Selector_PEnum.tourGuidance,
            Selector_PEnum.tags,
            Selector_PEnum.languageSpoken,
            Selector_PEnum.languageGuide,
            Selector_PEnum.demographic
        ],
        coverImageName: '',
        coverImageMediaType: ''
    }
}