import { sortObjectArray, updateOrAddItem } from '../../../utility/Utils'
import { TranslatableText } from '../../../utility/TranslatableText'
import { EntityType_PEnum, ProfileItemType_PEnum, ProfileItemUnitOfArea_PEnum, ProfileItemUnitOfDistance_PEnum } from '../../../configs/pseudoEnums'

// ** Initial State
const initialState = {
  profileItems: [],
  fullProfileItems: [],
  isValidated: false,
  isLoading: true,
  isSaving: false,
  isSubmitting: false,
  currentEditorLanguageCodes: {0: 'en'}   //Allows us to set the selected language tab from outside of the TranslatableTextEditor component. An optional key can be supplied to the TranslatableTextEditor if there is more than one TranslatableTextEditor on a page, so that changing the language tab on one does not affect the other.
}

export const getDefaultProfileItem = (profileId, profileItemType) => {
  return {
    id: '',
    features: [],
    name: TranslatableText.getNew(true),
    configuration: '',
    description: TranslatableText.getNew(true),
    entityType: EntityType_PEnum.profileItem,
    isChanged: false,
    isEditing: false,   //Set to true for item currently edited in the overlay (so we can match the DB version with the local version after save)
    media: [],
    profileId,
    sequence: 0,
    type: profileItemType,
    count: 1,
    size: 0,
    units: profileItemType === ProfileItemType_PEnum.venue ? ProfileItemUnitOfDistance_PEnum.metres : ProfileItemUnitOfArea_PEnum.squareMetres,
    existsInDb: false,
    inclusionType: '',   //activities-only property
    affiliate: '' //sleeping arrangement only property
  }
}

const profileItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROFILEITEM':
      return { ...state, ...state.profileItems.find(x => x.id === action.data)}
    case 'GET_PROFILEITEMS':
      return { ...state, profileItems: action.data }
    case 'SET_CURRENT_EDITOR_LANGUAGE_CODE':
      return { ...state, currentEditorLanguageCodes: action.data }
    case 'SET_IS_VALIDATED':
      return {...state, profile: {...state.profileItems, isValidated: action.data}}
    case 'SET_IS_LOADING':
      return {...state, profile: {...state.profileItems, isLoading: action.data}}
    case 'SET_IS_SUBMITTING':
      return {...state, profile: {...state.profileItems, isSubmitting: action.data}}
    case 'DELETE_PROFILEITEM':
      state.profileItems.splice(state.profileItems.findIndex(x => x.id === action.data), 1)
      //The cloneDeep is needed. Otherwise dependent useEffects will not fire. Even though an item has been deleted, and redux state updates!
      return {...state, profileItems: _.cloneDeep(state.profileItems) }
    case 'SET_PROFILEITEM':
      return {...state, 
        profileItems: sortObjectArray(updateOrAddItem(state.profileItems, action.data, 'id'), 'sequence')
      }
    case 'SET_SELECTED_PROFILE_ITEM':
      return {...state, selectedProfileItem: action.data }
    case 'SET_PROFILEITEMS':
      return { ...state, profileItems: action.data, isLoading: false, isSaving: false, isSubmitting: false
      }
    case 'SET_FULL_PROFILEITEMS':
      return { ...state, fullProfileItems: action.data, isLoading: false, isSaving: false, isSubmitting: false}
    case 'RESET_PROFILEITEMS':
      return {...state, 
        profileItems: initialState.profileItems,
        isValidated: initialState.isValidated,
        isLoading: initialState.isLoading,
        isSaving: initialState.isSaving,
        isSubmitting: initialState.isSubmitting
      }
    default:
      return state
  }
}

export default profileItemReducer
