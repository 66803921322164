export const ContactType_PEnum = Object.freeze({
    default: 1,
    accounts: 2,
    marketing: 3,
    sales: 4,
    content: 5,
    contact: 6,
    booking: 7,
    frontDesk: 8,
    socialMedia: 9
})

export const ContactFieldType_PEnum = Object.freeze({
    name: 1,
    telephone: 2,
    email: 3,
    skype: 4,
    link: 5,
    twitter: 6,
    website: 7,
    youtube: 8,
    facebook: 9,
    instagram: 10,
    pinterest: 11,
    flickr: 12,
    cellphone: 13,
    alternativeTelephone: 14,
    mobileBookingLink: 15,
    frontDeskPhone: 16,
    linkToSite: 17,
    onlineReservationUrl: 18,
    reservationTelephone: 19
})

export const EntityType_PEnum = {
    //These need to be upper-case to match the casing on the API/DB.
    none: 'None',
    profile: 'Profile',
    profileItem: 'ProfileItem',
    media: 'Media',
    translationItem: 'TranslationItem',
    directions: 'Directions'
}
export const ProfileItemUnitOfArea_PEnum = {
    squareMetres: 'SquareMetres',
    squareFeet: 'SquareFeet'
}

export const ProfileItemUnitOfDistance_PEnum = {
    metres: 'Metres',
    feet: 'Feet'
}

export const FeatureType_PEnum = {
    none: "None",
    language: "Language",
    facility: "Facility"
}

export const ProfileFormPage_PEnum = {
    none: "",
    basicInfo: "basicInfo",
    fastFacts: "fastFacts",
    media: "media",
    sleepingArrangements: "sleepingArrangements",
    facilities: "facilities",
    contactDetails: "contactDetails",
    location: "location",
    addedServices: "addedServices",
    rates: "rates",
    documents: "documents",
    integrations: "integrations"
}

export const ProfileItemType_PEnum = {
    none: 'None',
    activity: 'Activity',
    apartment: 'Apartment',
    cabana: 'Cabana',
    cabin: 'Cabin',
    carriage: 'Carriage',
    dome: 'Dome',
    feature: 'Feature',
    unit: 'Unit',
    villa: 'Villa',
    restaurant: 'Restaurant',
    room: 'Room',
    sleepingArrangement: 'SleepingArrangement',
    suite: 'Suite',
    tent: 'Tent',
    attraction: 'Attraction',
    spaces: 'Spaces',
    option: 'Option',
    itineraries: 'Itineraries',
    amenity:'Amenity',
    venue: 'Venue',
    stays: 'Stays',
    destinations: 'Destinations',
    package: 'Package',
    lodge: 'Lodge'
}

 export const sleepingArrangementTypes = [
    ProfileItemType_PEnum.room, ProfileItemType_PEnum.sleepingArrangement, ProfileItemType_PEnum.suite, ProfileItemType_PEnum.tent, 
    ProfileItemType_PEnum.unit, ProfileItemType_PEnum.villa, ProfileItemType_PEnum.lodge, ProfileItemType_PEnum.dome, ProfileItemType_PEnum.carriage, 
    ProfileItemType_PEnum.cabin, ProfileItemType_PEnum.cabana, ProfileItemType_PEnum.apartment
 ]
 
 export const MediaType_PEnum = {
    none: 'None',
    image: 'Image',
    video: 'Video',
    embedVideo: 'EmbedVideo',
    virtualTour: 'VirtualTour',
    panorama: 'Panorama',
    documentation: 'Documentation',
    splashImage: 'SplashImage',
    photosCoverImage: 'PhotosCoverImage',
    roomsCoverImage: 'RoomsCoverImage',
    videosCoverImage: 'VideosCoverImage',
    activitiesCoverImage: 'ActivitiesCoverImage',
    panoramasCoverImage: 'PanoramasCoverImage',
    destinationsCoverImage: 'DestinationsCoverImage',
    accommodationsCoverImage: 'AccommodationsCoverImage',
    amenitiesCoverImage: 'AmenitiesCoverImage',
    attractionsCoverImage: 'AttractionsCoverImage',
    documentsCoverImage: 'DocumentsCoverImage',
    facilitiesCoverImage: 'FacilitiesCoverImage',
    spacesCoverImage: 'SpacesCoverImage',
    libraryCoverImage: 'LibraryCoverImage',
    mapCoverImage: 'MapCoverImage',
    restaurantsCoverImage: 'RestaurantsCoverImage',
    sliderImages: 'SliderImages',
    specialsCoverImage: 'SpecialsCoverImage',
    travelGuidesCoverImage: 'TravelGuidesCoverImage',
    capacityAndDimensions: 'CapacityAndDimensions',
    whyStayHereCoverImage: 'WhyStayHereCoverImage',
    whyVisitHereCoverImage: 'WhyVisitHereCoverImage',
    whyDoThisCoverImage: 'WhyDoThisCoverImage',
    whyEatHereCoverImage: 'WhyEatHereCoverImage',
    whyConferenceHereCoverImage: 'WhyConferenceHereCoverImage',
    apartmentsCoverImage: 'ApartmentsCoverImage',
    cabanasCoverImage: 'CabanasCoverImage',
    cabinsCoverImage: 'CabinsCoverImage',
    carriagesCoverImage: 'CarriagesCoverImage',
    domesCoverImage: 'DomesCoverImage',
    unitsCoverImage: 'UnitsCoverImage',
    villasCoverImage: 'VillasCoverImage',
    sleepingArrangementsCoverImage: 'SleepingArrangementsCoverImage',
    suitesCoverImage: 'SuitesCoverImage',
    tentsCoverImage: 'TentsCoverImage',
    amenitiesCoverImage: 'AmenitiesCoverImage',
    venuesCoverImage: 'VenuesCoverImage',
    optionCoverImage: 'OptionCoverImage',
    staysCoverImage: "StaysCoverImage",
    bannerImage: "BannerImage"
}

export const imageMediaTypes = [
    MediaType_PEnum.image,
    MediaType_PEnum.splashImage,
    MediaType_PEnum.photosCoverImage,
    MediaType_PEnum.roomsCoverImage,
    MediaType_PEnum.videosCoverImage,
    MediaType_PEnum.activitiesCoverImage,
    MediaType_PEnum.panoramasCoverImage,
    MediaType_PEnum.destinationsCoverImage,
    MediaType_PEnum.accommodationsCoverImage,
    MediaType_PEnum.amenitiesCoverImage,
    MediaType_PEnum.attractionsCoverImage,
    MediaType_PEnum.documentsCoverImage,
    MediaType_PEnum.facilitiesCoverImage,
    MediaType_PEnum.libraryCoverImage,
    MediaType_PEnum.mapCoverImage,
    MediaType_PEnum.restaurantsCoverImage,
    MediaType_PEnum.sliderImages,
    MediaType_PEnum.specialsCoverImage,
    MediaType_PEnum.travelGuidesCoverImage,
    MediaType_PEnum.whyStayHereCoverImage,
    MediaType_PEnum.apartmentsCoverImage,
    MediaType_PEnum.cabanasCoverImage,
    MediaType_PEnum.cabinsCoverImage,
    MediaType_PEnum.carriagesCoverImage,
    MediaType_PEnum.domesCoverImage,
    MediaType_PEnum.unitsCoverImage,
    MediaType_PEnum.villasCoverImage,
    MediaType_PEnum.sleepingArrangementsCoverImage,
    MediaType_PEnum.suitesCoverImage,
    MediaType_PEnum.tentsCoverImage
]

export const videoMediaTypes = [
    MediaType_PEnum.video,
    MediaType_PEnum.embedVideo
]

export const DocumentType_PEnum = {
    none: 'None',
    covidNotice: 'CovidNotice',
    factSheet: 'FactSheet',
    rates: 'Rates',
    specials: 'Specials',
    foodAndBeverage: 'FoodAndBeverage',
    brochure: 'Brochure',
    conferencing: 'Conferencing',
    directions: 'Directions',
    faqs: 'Faqs',
    menu: 'Menu',
    activities: 'Activities',
    spa: 'Spa',
    weddings: 'Weddings',
    otherGeneral: 'OtherGeneral'
}

export const ProfileStatus_PEnum = Object.freeze({
    //This must match Core\Enums\ProfileStatusEnum in the API.
    InProgress: 'InProgress',
    InReview: 'InReview',
    Disabled: 'Disabled',
    Active: 'Active',
    Declined: 'Declined'
})

export const ProfileTier_DB_PEnum = {
    //Enum for profile tier setting in DB. NOT to be used for UI labels.
    Basic: 'Basic',
    Lite: 'Lite',
    Professional: 'Professional'
}

export const ProfileTier_UI_PEnum = {
    //Enum for profile tier UI labels. NOT to be used for data saving/retrieval.
    //Seperate enum to because we may want to make aesthetic tweaks to the UI label without it affecting data retrieval.
    Basic: 'Basic',
    Professional: 'Professional'
}

export const DocumentTypes_DB_PEnum = {
    covidNotice: 'CovidNotice',
    factSheet: 'FactSheet',
    rates: 'Rates',
    specials: 'Specials',
    foodAndBeverage: 'FoodAndBeverage',
    brochure: 'Brochure',
    conferencing: 'Conferencing',
    directions: 'Directions',
    faqs: 'Faqs',
    menu: 'Menu',
    activities: 'Activities',
    spa: 'Spa',
    weddings: 'Weddings',
    otherGeneral: 'OtherGeneral'
}

export const ProfileItemVisibility_DB_PEnum = {
    publicAndTrade: 'PublicAndTrade',
    tradeOnly: 'TradeOnly',
    private: 'Private'
}

export const getVisibilityUiLabel = (enumValue) => {
    return enumValue === ProfileItemVisibility_DB_PEnum.publicAndTrade ?
        'Public and trade' :
        enumValue === ProfileItemVisibility_DB_PEnum.tradeOnly ?
            'Trade only' :
            'Private'
}

export const AssociationType_DB_Enum = {
    none: 'None',
    airport: 'Airport',
    campSite: 'CampSite',
    destination: 'Destination',
    activity: 'Activity',
    stay: 'Stay'
}

export const DirectionType_PEnum = {
    //Enum to differentiate between directions to property and from property.
    directionTo: 'directionTo',
    directionFrom: 'directionFrom'
}

export const ProfileType_PEnum = {
    accommodation: 'Accommodation',
    activity: 'Activity',
    venues: 'Venues',
    restaurant: 'Restaurant',
    destination: 'Destination',
    attraction: 'Attraction',
    information: 'Information',
    infrastructure: 'Infrastructure',
    other:'Other'
}

export const ProfileCategory_PEnum = {
    mobileSafari: "MobileSafari",
    hotel: "Hotel",
    guestHouse: "GuestHouse",
    shortTermRental: "ShortTermRental",
    villa: "Villa",
    lodge: "Lodge",
    motel: "Motel",
    rail: "Rail",
    boat: "Boat",
    camp: "Camp",
    vehicle: "Vehicle",
    adventure: "Adventure",
    leisure: "Leisure",
    outdoor: "Outdoor",
    sport: "Sport",
    waterSport: "Watersport",
    african: "African",
    asian: "Asian",
    contemporary: "Contemporary",
    deli: "Deli",
    fastFoods: "FastFoods",
    fineDining: "FineDining",
    fusion: "Fusion",
    grill: "Grill",
    indian: "Indian",
    local: "Local",
    portuguese: "Portuguese",
    seafood: "Seafood",
    sushi: "Sushi",
    entertainment: "Entertainment",
    historyCulture: "HistoryCulture",
    nature: "Nature",
    shopping: "Shopping",
    wildlife: "Wildlife",
    information: "Information",
    meetAndGreet: "MeetAndGreet",
    person: "Person",
    internationalAirport: "InternationalAirport",
    airField: "Airfield",
    airport: "Airport",
    harbour: "Harbour",
    trainStation: "TrainStation",
    bedBreakfast: "BedBreakfast",
    corporateFacility: "CorporateFacility",
    expoCentre: "ExpoCentre",
    guestHousesLodges: "GuestHousesLodges",
    tentedOutdoorCamp: "TentedOutdoorCamp",
    area: "Area",
    dmo: "DMO",
    other: "Other",
    variant: "Variant",
    country: "Country"
}

export const getAllCategoriesExcept = (enumList = []) => {
    const ProfileCategoryList = Object.values(ProfileCategory_PEnum)
    enumList.forEach(enumValue => {
        const indexOfEnum = ProfileCategoryList.findIndex((enn) => enn ===  enumValue)
        ProfileCategoryList.splice(indexOfEnum, 1)
    })
    return ProfileCategoryList
}

export const accommodationCategoryList = [
    ProfileCategory_PEnum.hotel, ProfileCategory_PEnum.guestHouse, ProfileCategory_PEnum.shortTermRental, ProfileCategory_PEnum.villa,
    ProfileCategory_PEnum.lodge, ProfileCategory_PEnum.motel, ProfileCategory_PEnum.camp
]

export const activityCategoryList = [
    ProfileCategory_PEnum.adventure, ProfileCategory_PEnum.leisure, ProfileCategory_PEnum.outdoor,
    ProfileCategory_PEnum.sport, ProfileCategory_PEnum.waterSport
]

export const foodAndBeverageCategoryList = [
    ProfileCategory_PEnum.african, ProfileCategory_PEnum.asian, ProfileCategory_PEnum.contemporary, ProfileCategory_PEnum.deli, ProfileCategory_PEnum.fastFoods,
    ProfileCategory_PEnum.fineDining, ProfileCategory_PEnum.fusion, ProfileCategory_PEnum.grill, ProfileCategory_PEnum.indian, ProfileCategory_PEnum.local,
    ProfileCategory_PEnum.portuguese, ProfileCategory_PEnum.seafood, ProfileCategory_PEnum.sushi
]

export const attractionAndLandMarksCategoryList = [
    ProfileCategory_PEnum.entertainment, ProfileCategory_PEnum.historyCulture, ProfileCategory_PEnum.leisure, ProfileCategory_PEnum.nature,
    ProfileCategory_PEnum.shopping, ProfileCategory_PEnum.wildlife    
]

export const informationAndServicesCategoryList = [ProfileCategory_PEnum.information, ProfileCategory_PEnum.person, ProfileCategory_PEnum.meetAndGreet]

export const infrastructureCategoryList = [
    ProfileCategory_PEnum.internationalAirport, ProfileCategory_PEnum.airField, ProfileCategory_PEnum.airport,
    ProfileCategory_PEnum.harbour, ProfileCategory_PEnum.trainStation
]

export const venueCategoryList = [
    ProfileCategory_PEnum.bedBreakfast, ProfileCategory_PEnum.corporateFacility, ProfileCategory_PEnum.expoCentre,
    ProfileCategory_PEnum.guestHousesLodges, ProfileCategory_PEnum.tentedOutdoorCamp
]
    
export const Selector_PEnum = {
    qualityAssurance: 'qualityAssurance',
    numberOfRooms: 'numberOfRooms',
    maxGroupSize: 'maxGroupSize',
    numberOfSeats: 'numberOfSeats',
    numberOfTents: 'numberOfTents',
    numberOfCabins:'numberOfCabins',
    minChildAge: 'minChildAge',
    checkInTime: 'checkInTime',
    operatingHours: 'operatingHours',
    airportCode: 'airportCode',
    requirements: 'requirements',
    skills: 'skills',
    friendly: 'friendly',
    restaurantStyle: 'restaurantStyle',
    specialInterests: 'specialInterests',
    tourGuidance: 'tourGuidance',
    participation: 'participation',
    sleepingArrangement: 'sleepingArrangement',
    inclusion: 'inclusion',
    facilities: 'facilities',
    roomPlan:'roomPlan',
    tags: 'tags',
    capacityAndDimensions:'capacityAndDimensions',
    qualityAssurance: 'qualityAssurance',
    numberOfAccommodations: 'numberOfAccommodations',
    numberOfRestaurants: 'numberOfRestaurants',
    numberOfLounges: 'numberOfLounges',
    numberOfTerminals: 'numberOfTerminals',
    numberOfShops: 'numberOfShops',
    languageSpoken: 'languageSpoken',
    languageGuide: 'languageGuide',
    demographic: 'demographic',
    venueDetails: 'venueDetails',
    venueEquipment: 'venueEquipment',
    availableAccommodationOnsite: 'availableAccommodationOnsite',
    availableAccommodationOffsite: 'availableAccommodationOffsite'
}

export const InclusionType_PEnum = Object.freeze({
    none: 'None',
    optional: 'Optional',
    included: 'Included', 
    extra: 'Extra'
})

export const PricingConfigType_PEnum = Object.freeze({
    //Corresponds to API enum PricingConfigType.
    none: 'None',
    single: 'Single',
    perPerson: 'PerPerson',
    perPersonSharing: 'PerPersonSharing',
    perPersonPerNightSharing: 'PerPersonPerNightSharing',
    perRoomSuite: 'PerRoomSuite',
    perUnitVilla: 'PerUnitVilla',
    age: 'Age',
    pax: 'Pax'
})

export const PricingPax_PEnum = Object.freeze({
    pps: 'PPS',
    pp: 'PP'
})

export const EmailSuggestionType_PEnum = Object.freeze({
    CategorySuggestionEmails: 'CategorySuggestionEmails',
    TypeSuggestionEmails: 'TypeSuggestionEmails',
    DmoSuggestionEmails: 'DmoSuggestionEmails'
})

export const StatusNotification_PEnum = Object.freeze({
    //This must match Core\Enums\StatusNotification in the API.
    None: 'None',
    Approved: 'Approved',
    Declined: 'Declined'
})

export const Affiliate_PEnum = Object.freeze({
    //This must match Core\Enums\BookingAffiliateEnum in the API.
    None: 'None',
    Nightsbridge: 'Nightsbridge',
    ResRequest: 'ResRequest'
})

export const MtStatus_PEnum = Object.freeze({
    //This must match Core\Enums\TranslationsInProgressStatusEnum in the API.
    Pending: 'Pending',
    SomeSucceeded: 'SomeSucceeded',
    NoneSucceeded: 'NoneSucceeded',
    AllSucceeded: 'AllSucceeded',
    ServiceDisabled: 'ServiceDisabled'      //Service can be disabled via EnableMachineTranslations in API appsettings.json - in case of serious error or malicious use.
})
